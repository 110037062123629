import { assetsCDNUrl } from '@/helpers/http/url';

const HtmlMetas = () => (
    <>
        <meta
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimal-ui"
            name="viewport"
        />

        <meta content="189fcdc5a47e" name="bitly-verification" />
        <meta content="647198951" property="fb:admins" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="black" name="apple-mobile-web-app-status-bar-style" />

        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-57x57.png')}
            rel="apple-touch-icon"
            sizes="57x57"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-60x60.png')}
            rel="apple-touch-icon"
            sizes="60x60"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-72x72.png')}
            rel="apple-touch-icon"
            sizes="72x72"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-76x76.png')}
            rel="apple-touch-icon"
            sizes="76x76"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-114x114.png')}
            rel="apple-touch-icon"
            sizes="114x114"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-120x120.png')}
            rel="apple-touch-icon"
            sizes="120x120"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-144x144.png')}
            rel="apple-touch-icon"
            sizes="144x144"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-152x152.png')}
            rel="apple-touch-icon"
            sizes="152x152"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/apple-icon-180x180.png')}
            rel="apple-touch-icon"
            sizes="180x180"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/android-icon-192x192.png')}
            rel="icon"
            sizes="192x192"
            type="image/png"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/favicon-32x32.png')}
            rel="icon"
            sizes="32x32"
            type="image/png"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/favicon-96x96.png')}
            rel="icon"
            sizes="96x96"
            type="image/png"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/favicon-16x16.png')}
            rel="icon"
            sizes="16x16"
            type="image/png"
        />
        <link
            href={assetsCDNUrl('common/browser-icons/202105/favicon.ico')}
            rel="icon shortcut"
            type="image/x-icon"
        />
        <link href={assetsCDNUrl('common/browser-icons/202105/manifest.json')} rel="manifest" />
        <meta content="#1e91ff" name="msapplication-TileColor" />
        <meta
            content={assetsCDNUrl('common/browser-icons/202105/ms-icon-144x144.png')}
            name="msapplication-TileImage"
        />
        <meta content="#1e91ff" name="theme-color" />
    </>
);

export default HtmlMetas;

// This file configures the intialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// Sentry is only init client-side in BaseLayout.tsx after retrieving datalayer dispatcher
// This file is needed to make sentry/nextjs working

import * as Sentry from '@sentry/nextjs';
import {
    defaultInitConfig,
    externalIgnoreErrors,
    externalDenyUrls,
    maIgnoreErrors,
    maDenyUrls,
    customBeforeSend,
} from '@/config/sentry';
import { getPublicConfig } from '@/helpers/config/getConfig';

Sentry.init({
    ...defaultInitConfig,
    sampleRate: getPublicConfig('SENTRY_DEBUG') ? 1 : 0,
    ignoreErrors: [...externalIgnoreErrors, ...maIgnoreErrors],
    denyUrls: [...externalDenyUrls, ...maDenyUrls],
    beforeSend: (event, hint) => {
        try {
            return customBeforeSend(event, hint);
        } catch {
            return event;
        }
    },
});

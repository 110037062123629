import { translate } from '@ma-js-common/translate';
import { setLocale } from 'yup';

export const required = translate('Ce champ est requis');
export const oneOf = ({ values }: { values: string }) =>
    translate("Ce champ requiers l'une de ces valeurs : %s", [values]);
export const email = translate("Ce format d'email est invalide");

setLocale({
    mixed: {
        required,
        oneOf,
    },
    string: {
        email,
    },
});

import { useState } from 'react';
import { createContainer } from 'react-tracked';

import { Realtor } from '@/entities/types/DataLayer';
import type User from '@/entities/types/User';

export type userState = {
    userHasBeenRequested: boolean;
    user?: User;
    realtor?: Realtor;
};
const useMyState = () => useState<userState>({ userHasBeenRequested: false });

export const { Provider: UserProvider, useTracked: useUser } = createContainer(useMyState);

import { getPublicConfig } from '@/helpers/config/getConfig';

const initCookieBanner = () => {
    if (!window.cookieBannerInit) {
        console.warn('Cookie Banner was not correctly initialized');
        return;
    }
    window.cookieBannerInit({
        trackingID: getPublicConfig('GA_ACCOUNT') as string,
        pageCategory: 'www-next',
        cookiesDomain: getPublicConfig('GA_DOMAIN') as string,
    });
};

export { initCookieBanner };

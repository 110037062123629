const realytics = `

const REALYTICS_ID = 'ry-m31lle2';

/**
 * Initialize Realytics tracking by injecting script in the DOM
 */
function initialize() {
    window.RY = (function (e) {
        let t = [
            'identify',
            'track',
            'trackLink',
            'trackForm',
            'transaction',
            'page',
            'profile',
            'sync',
        ];
        let n = 'realytics';
        let r = function (e) {
            return !!(e && (typeof e == 'function' || typeof e == 'object'));
        };
        let i = function (e, t) {
            return function () {
                let n = Array.prototype.slice.call(arguments);
                if (!e[t]) e[t] = [];
                e[t].push(n ? n : []);
                if (!e['_q']) e['_q'] = [];
                e['_q'].push(t);
            };
        };
        let s = function (r) {
            for (let s = 0; s < t.length; s++) {
                let o = t[s];
                if (r) e[r][o] = i(e._q[r], o);
                else e[o] = e[n][o] = i(e._q[n], o);
            }
        };
        let o = function (t, r, i) {
            let o = t ? t : n;
            if (!e[o]) e[o] = {};
            if (!e._q[o]) e._q[o] = {};
            if (r) e._q[o]['init'] = [[r, i ? i : null]];
            s(t);
        };
        if (!e._v) {
            if (!e._q) {
                e._q = {};
                o(null, null, null);
            }
            e.init = function (e, n) {
                let i = n ? (r(n) ? (n['name'] ? n['name'] : null) : n) : null;
                if (i && t) for (let s = 0; s < t.length; s++) if (i == t[s] || i == 'init') return;
                o(i, e, r(n) ? n : null);
                let u = function (e) {
                    let t = document.createElement('script');
                    t.type = 'text/javascript';
                    t.async = true;
                    t.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + e;
                    let n = document.getElementsByTagName('script')[0];
                    n.parentNode.insertBefore(t, n);
                };
                u('i.realytics.io/tc.js?cb=' + new Date().getTime());
                u('cdn-eu.realytics.net/realytics-1.2.min.js');
            };
        }
        return e;
    })(window.RY || {});

    window.RY.init(REALYTICS_ID);
    window.RY.page();
}

initialize();
`;

export default realytics;

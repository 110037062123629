import { initConfig, initTracking } from '@ma-js-common/wa-client';
import type { WAEventData } from '@ma-js-common/wa-client/dist/types/Wa';
import { captureException } from '@sentry/browser';
import urlJoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';

import type Place from '@/entities/types/Place';
import { getPublicConfig } from '@/helpers/config/getConfig';

export type EventDataPlaceType = WAEventData['place_type'];

/**
 * WebAnalytics default events data
 */
type DefaultWaData = {
    pageview_id?: string;
    place_id?: number;
    place_type?: EventDataPlaceType;
};

export const defaultWaData: DefaultWaData = {
    pageview_id: uuidv4(),
};

export function updatePageviewId() {
    defaultWaData.pageview_id = uuidv4();
}

export function updatePlace(place?: Place) {
    defaultWaData.place_id = place?.id;
    defaultWaData.place_type = place?.type as EventDataPlaceType;
}

export function initWa() {
    initConfig({
        receiverUrl: urlJoin(getPublicConfig('WEBANALYTICS_URL') as string, 'events'),
        appName: 'www',
        debug: getPublicConfig('WA_DEBUG') as boolean,
        sentryCaptureException: captureException,
        apiKey: getPublicConfig('GATEWAY_API_KEY') as string,
        useBeacon: false, // @TODO enable beacon and monitor with BI to see if events are lost
    });
    initTracking();
}

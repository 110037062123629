import { getGaCategoryFromUrl } from '@ma-js-common/ga-helpers';
import { translate } from '@ma-js-common/translate';
import { Button, Text } from '@ma-react/components';
import { StoreAlt } from '@ma-react/icons';
import { useRouter } from 'next/router';
import urlJoin from 'url-join';

import { getPublicConfig } from '@/helpers/config/getConfig';

import styles from './showcase-banner.module.scss';

const ShowcaseBanner = () => {
    const router = useRouter();
    const currentPathname = router.asPath.split('?')[0];
    const extranetUrl = urlJoin(getPublicConfig('WWW_URL') as string, '/agency-register');

    return (
        <div className={styles['showcase-banner']} data-testid="showcase-banner">
            <StoreAlt className={styles['showcase-banner__icon']} />
            <div className={styles['showcase-banner__content']}>
                <Text className={styles['showcase-banner__text']} variant="negative">
                    {translate(
                        'Présentez gratuitement votre savoir-faire aux particuliers qui estiment leurs biens sur Meilleurs Agents',
                    )}
                </Text>
                <Button
                    as="a"
                    className={styles['showcase-banner__cta']}
                    color="neutral-0"
                    data-testid="showcase-banner-cta"
                    data-ua-event-action="go:agency_register:landing"
                    data-ua-event-category={getGaCategoryFromUrl(currentPathname)}
                    data-ua-event-label="pro:top_banner:create_agency_button"
                    data-ua-hit-type="event"
                    href={extranetUrl}
                    size="large"
                    variant="outlined"
                >
                    {translate('Créer votre vitrine gratuite')}
                </Button>
            </div>
        </div>
    );
};

export default ShowcaseBanner;

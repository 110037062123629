import { useEffect } from 'react';

import GaCategory from '@/entities/enums/GaCategory';
import type AnalyticsUser from '@/entities/types/AnalyticsUser';
import type Dispatcher from '@/entities/types/Dispatcher';
import type FieldsObject from '@/entities/types/FieldsObject';
import type { EventArgs, GaTemplates } from '@/entities/types/Ga';
import type { ReferrerOriginParams } from '@/front/helpers/Referrer';
import { getSearchParamsAsObject, referrerOrigin } from '@/front/helpers/Referrer';
import { getPublicConfig } from '@/helpers/config/getConfig';

let gaSource = 'unknown';
let isTrackingInitialized = false;

const PRICES_ENGAGE_ACTIONS = {
    'go:estima': 'prices:engage:estima',
    'go:estima_landing_generic': 'prices:engage:estima',
    'go:estima_landing_apartment': 'prices:engage:estima',
    'go:estima_landing_house': 'prices:engage:estima',
    'go:estima_landing_rental': 'prices:engage:estima',
    'go:realtor_page': 'prices:engage:realtor',
    'go:realtor:contact:open': 'prices:engage:realtor',
    'go:past_sale': 'prices:engage:past_sale',
    'go:eval_request_ma': 'prices:engage:eval_request',
    'go:listings': 'prices:engage:listings',
    'go:find_a_pro': 'prices:engage:find_a_pro',
    'go:find_a_pro:city': 'prices:engage:find_a_pro',
    'go:find_a_pro:arrmun': 'prices:engage:find_a_pro',
    'go:find_a_pro:borough': 'prices:engage:find_a_pro',
    'go:find_a_pro:street': 'prices:engage:find_a_pro',
    'go:find_a_pro:address': 'prices:engage:find_a_pro',
    'realtor:show_more': 'prices:engage:realtor',
    'go:past_sales_report': 'prices:engage:past_sales_report',
    'go:rental_price': 'prices:engage:rental_tab',
    'go:sell_price': 'prices:engage:sell_tab',
};

const HOME_ENGAGE_ACTIONS = {
    'go:estima_form': 'home:engage:estima',
    'go:all_pros:subregion': 'home:engage:compare',
    'go:all_pros:city': 'home:engage:compare',
    'go:all_pros:subcity': 'home:engage:compare',
    'go:all_pros:arrmun': 'home:engage:compare',
};

function initWindowGoogleAnalyticConfig() {
    if (!window.GoogleAnalyticsConfig) {
        window.GoogleAnalyticsConfig = {
            debug: getPublicConfig('GA_DEBUG') as boolean,
            gaAccount: getPublicConfig('GA_ACCOUNT') as string,
            tagsQueue: [],
        };
    }
}

export function getSource() {
    return gaSource;
}

export function setSource(source: string) {
    gaSource = source;
}

// @see documentation at https://developers.google.com/analytics/devguides/collection/analyticsjs
function ga(...args: Array<string | unknown>) {
    if (window.ga) {
        window.ga(...args);
    } else {
        initWindowGoogleAnalyticConfig();
        window.GoogleAnalyticsConfig.tagsQueue.push(args);
    }
}

export function gaEvent(event: EventArgs, hitCallback?: () => void, triggerGaEngage = true): void {
    gaSend(event, hitCallback);

    if (triggerGaEngage) {
        sendEngageEvent(GaCategory.HOME_PAGE, 'home:engage', HOME_ENGAGE_ACTIONS, event);
        sendEngageEvent(GaCategory.EXPERTISE_PAGE, 'prices:engage', PRICES_ENGAGE_ACTIONS, event);
    }
}

function sendEngageEvent(
    engageCategory: GaCategory,
    engageAction: string,
    pageEngageActions: { [key: string]: string },
    gaParameters: EventArgs,
) {
    if (gaParameters.category === engageCategory && gaParameters.action in pageEngageActions) {
        gaSend({
            ...gaParameters,
            label: pageEngageActions[gaParameters.action],
            action: engageAction,
        });
    }
}

/**
 * DO NOT USE to send GA Events, prefer gaEvent in order to check engage events
 * gaSend is only exported for testing purposes
 */
function gaSend(event: EventArgs, hitCallback?: () => void) {
    const { category, action, label, ...restEvent } = event;
    if (typeof hitCallback === 'function') {
        ga('send', 'event', category, action, label, {
            hitCallback: functionWithTimeout(hitCallback),
        });
    } else {
        // @see polymorphism at https://developers.google.com/analytics/devguides/collection/analyticsjs/pages#examples
        ga('send', {
            hitType: 'event',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            ...restEvent,
        });
    }
}

export function gaPageView(
    path: string,
    { basename = '', pageTitle = undefined }: { basename?: string; pageTitle?: string } = {},
) {
    const page = `${basename || ''}${path}`;

    const fields: { hitType: 'pageview'; page: string; title?: string } = {
        hitType: 'pageview',
        page,
    };

    if (pageTitle) {
        fields.title = pageTitle;
    }
    ga('send', fields);
}

export function gaSet(params: EventArgs | FieldsObject | GaTemplates) {
    ga('set', params);
}

const anonymousAnalyticsUser: AnalyticsUser = {
    authenticated: false,
    type: 'Anonymous',
};

export function setUserDimensions(analyticsUser: AnalyticsUser = anonymousAnalyticsUser) {
    gaSet({ dimension1: analyticsUser.type });

    if (analyticsUser.authenticated) {
        gaSet({
            userId: analyticsUser.id,
            dimension2: analyticsUser.dates,
            dimension5: analyticsUser.segment,
        });
    } else {
        gaSet({ dimension5: analyticsUser.type });
    }
}

export function setABTestsDimensions(abDispatcher?: Dispatcher | null) {
    if (!abDispatcher) {
        return;
    }

    // /!\ Message to DEVs : Keep this commented template for future AB test ⬇
    // When needed, copy template, uncomment and replace values between <> with your AB test values
    /*
    let dimensionValue: string;
    // A/B <custom_ab_test_dispatcher>
    dimensionValue = '<reference:value>';
    if (abDispatcher?.<custom_ab_test_dispatcher>) {
        dimensionValue = '<test:value>';
    }
    gaSet({ <dimensionN>: dimensionValue });
    */
}

export function uaEventHandler(element: HTMLElement) {
    const parameters = ['category', 'action', 'label', 'value'].reduce((p, key) => {
        const value = element.getAttribute(`data-ua-event-${key}`);

        if (typeof value !== 'undefined' && value !== null) {
            p[key] = value;
        }

        return p;
    }, {} as FieldsObject);

    parameters.nonInteraction =
        Boolean(element.getAttribute('data-ua-non-interaction')) || undefined;

    gaEvent(parameters as unknown as EventArgs);
}

export function clickHandler(event: MouseEvent) {
    const element = (event.target as HTMLElement).closest<HTMLElement>('[data-ua-hit-type]');
    if (element) {
        uaEventHandler(element);
    }
}

// Init tracking on all click and for submit event
function initializeTracking() {
    if (!isTrackingInitialized) {
        // Catch elements for Google Universal Analytics tracking
        document.addEventListener('click', clickHandler);
        isTrackingInitialized = true;
    }
}

// @dev Don't forget to setUserDimensions in the app too
export function initAnalytics(): void {
    initWindowGoogleAnalyticConfig();
    initializeTracking();

    setSource(referrerOrigin(getSearchParamsAsObject() as unknown as ReferrerOriginParams));
}

export function useGaScrollEvents(
    sections: Array<{ selector: string; category: string; label: string }>,
): void {
    const sectionViewed: Array<string> = [];
    let scrollDebounceTimeout: NodeJS.Timeout | null;

    function checkVisibility(elementSelector: string, category: string, label: string) {
        const element = document.querySelector(elementSelector);
        if (element && !sectionViewed.includes(elementSelector)) {
            const elementRect = element.getBoundingClientRect();
            if (elementRect.top + elementRect.height / 2 < window.innerHeight) {
                gaEvent({
                    category,
                    action: 'section_displayed',
                    label,
                    nonInteraction: true,
                });
                sectionViewed.push(elementSelector);
            }
        }
    }

    function onScrollListener() {
        if (scrollDebounceTimeout) {
            clearTimeout(scrollDebounceTimeout);
            scrollDebounceTimeout = null;
        }

        scrollDebounceTimeout = setTimeout(() => {
            for (let i = 0; i < sections.length; i++) {
                const { selector, category, label } = sections[i];

                checkVisibility(selector, category, label);
            }

            if (sectionViewed.length >= sections.length) {
                document.removeEventListener('scroll', onScrollListener);
            }
        }, 300);
    }

    useEffect(() => {
        document.addEventListener('scroll', onScrollListener);
        onScrollListener();

        return function cleanup() {
            document.removeEventListener('scroll', onScrollListener);
        };
    });
}

/**
 * Wrap a function with a max timeout to execute.
 * @param {Function} callback Function to call
 * @param {Integer} timeout Timeout in ms
 * @return {Function} Wrapped function
 */
export function functionWithTimeout(callback: () => void, timeout = 2000) {
    let called = false;
    // eslint-disable-next-line prefer-const
    let token: NodeJS.Timeout;

    function wrapped() {
        if (!called) {
            called = true;
            clearTimeout(token);
            callback();
        }
    }

    token = setTimeout(wrapped, timeout);
    return wrapped;
}

import { getPublicConfig } from '@/helpers/config/getConfig';
import { EventHint, Event } from '@sentry/types';

export const defaultInitConfig = {
    dsn: getPublicConfig('SENTRY_DSN') as string,
    environment: getPublicConfig('SENTRY_ENVIRONMENT') as string,
    debug: getPublicConfig('SENTRY_DEBUG') as boolean,
};

export const customBeforeSend = (event: Event, hint?: EventHint) => {
    if (hint?.originalException instanceof Error) {
        const isFromMapbox = hint.originalException.stack?.includes('mapbox');

        // Prevent sending Safari known error of mapbox aborting multiple tiles fetch
        // See https://github.com/mapbox/mapbox-gl-js/issues/8480
        if (isFromMapbox && event?.message === 'AbortError: Fetch is aborted') {
            return null;
        }
    }

    return event;
};

export const maIgnoreErrors = [
    'Non-Error promise rejection captured with value: Object Not Found Matching',
];

export const maDenyUrls = [/haussmann\.meilleursagents\.net/];

/** Copy from https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a **/
export const externalIgnoreErrors = [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
];

export const externalDenyUrls = [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];
/** End copy **/

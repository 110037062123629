export const ASSETS_CDN_URL = 'https://assets.meilleursagents.com/web/';

export const PRICE_PAGE_PREFIX = 'prix-immobilier-new';
export const AGENCY_COMPARE_PAGE_PREFIX = 'professionnel-immobilier';

// ShortContactForm MFE (aka SCF)
export const SCF_AV_CATEGORY = 'personal_consultation';
export const SCF_AV_INITIATIVE = 'wl_short_contact';
export const SCF_INTEGRATION_TYPE = 'SHORT_CONTACT_FORM';
export const SCF_LANG = 'fr';
export const SCF_ON_ANALYTICS = 'onAnalytics';
export const SCF_PORTAL = 'MAG-FRA';
export const SCF_PARCEL_NAME = 'shortContactForm';
export const SCF_THEME = 'MA_THEME';

import env from '@beam-australia/react-env';
import nextGetConfig from 'next/config';

// Only holds serverRuntimeConfig and publicRuntimeConfig
const { serverRuntimeConfig } = nextGetConfig() || {};
function booleanCast(value: string) {
    switch (value) {
        case 'false':
            return false;
        case 'true':
            return true;
        default:
            return value;
    }
}

export function getServerConfig(key: string) {
    return booleanCast(serverRuntimeConfig[key]);
}

export function getPublicConfig(key: string) {
    return booleanCast(env(key));
}

import type { NextApiResponse } from 'next';
import urljoin from 'url-join';

import { getPublicConfig } from '@/helpers/config/getConfig';
import { httpError } from '@/helpers/http/error';

const request = async (url: string, options: RequestInit = {}) =>
    fetch(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        ...options,
    })
        .then(async (res) => {
            if (res.status >= 400) {
                const error = httpError(res);
                throw error;
            }
            return res;
        })
        .then((res) => res.json());

export const wwwRequest = (
    path: string,
    { method = 'get', credentials = 'include', ...options }: RequestInit = {},
) => {
    const wwwURL = getPublicConfig('WWW_URL') as string;
    return request(urljoin(wwwURL, path), {
        method,
        credentials,
        ...options,
    });
};

/**
 * Return a HTTP response error
 * @param res Pending response from NextJS
 * @param message Error message
 * @param code HTTP status code
 */
export function responseError(res: NextApiResponse, message: string, code = 500) {
    res.status(code).json({
        message,
    });
}

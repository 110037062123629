import type { LayoutProps } from '@/front/containers/BaseLayout';

import styles from './default-layout.module.scss';

const DefaultLayout = ({ header, banners, body, footer }: LayoutProps) => {
    return (
        <div className={styles['default-layout']}>
            <header>{header}</header>
            <div className={styles['default-layout__banners']}>{banners}</div>
            {/* Using tabIndex={-1} allow us to focus the node using JS but ensure the element is not reachable via sequential keyboard navigation.
            (See effect written in _app.tsx) */}
            <main className={styles['default-layout__body']} id="main-content" tabIndex={-1}>
                {body}
            </main>
            {footer}
        </div>
    );
};

export default DefaultLayout;

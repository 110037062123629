import { useEffect, useMemo } from 'react';
import TagManager from 'react-gtm-module';
import { initCriteo, sendCriteoEvent } from '@ma-js-common/criteo-helpers';
import { useRouter } from 'next/router';

import type { GaPageData } from '@/entities/types/Ga';
import { useDispatcher } from '@/front/context/DispatcherContext';
import { useUser } from '@/front/context/UserContext';
import {
    gaEvent,
    gaPageView,
    gaSet,
    initAnalytics,
    setABTestsDimensions,
    setUserDimensions,
} from '@/front/helpers/analytics/Ga';
import { mapDataLayerUser } from '@/front/helpers/userFromDataLayer';
import { getPublicConfig } from '@/helpers/config/getConfig';
import { wwwRequest } from '@/helpers/http/request';

export interface DataLayerPageData {
    category: string;
    template: string;
}

const usePageContext = ({
    gaPageData: { templates: gaPageTemplates, events: gaPageEvents, pageTitle } = {},
    dataLayerPageData: { category: dataLayerPageCategory, template: dataLayerPageTemplate } = {
        category: '',
        template: '',
    },
    shouldSendCriteoPageView,
}: {
    gaPageData?: GaPageData;
    dataLayerPageData?: DataLayerPageData;
    shouldSendCriteoPageView?: boolean;
}) => {
    const router = useRouter();
    const isErrorPage = useMemo(
        () => ['/404', '/500'].includes(router.pathname),
        [router.pathname],
    );
    const [userState, setUserState] = useUser();
    const [dispatcher, setDispatcher] = useDispatcher();

    useMemo(() => {
        if (!isErrorPage) {
            if (!dataLayerPageCategory) {
                console.warn(`You forgot to define dataLayer's category for this page`);
            }
            if (!dataLayerPageTemplate) {
                console.warn(`You forgot to define dataLayer's template for this page`);
            }
        }
    }, [dataLayerPageCategory, dataLayerPageTemplate, isErrorPage]);

    useEffect(() => {
        if (isErrorPage) return;
        initAnalytics();
        const gtmId = getPublicConfig('GTM_ID') as string;
        if (gtmId) TagManager.initialize({ gtmId });
        if (shouldSendCriteoPageView)
            initCriteo(getPublicConfig('CRITEO_ID') as string, window.MA_USER?.username);
    }, [isErrorPage, shouldSendCriteoPageView]);

    useEffect(() => {
        let mounted = true;

        wwwRequest('/datalayer').then((data) => {
            if (!mounted) return; // Prevent to do anything if the component is unmounted when the Promise resolve

            setUserState(mapDataLayerUser(data));

            if (isErrorPage) return; // No ga or gtm data should be sent if page is a 404

            TagManager.dataLayer({
                dataLayer: {
                    page: {
                        category: dataLayerPageCategory,
                        template: dataLayerPageTemplate,
                    },
                    user: data.user,
                    realtor: data.realtor,
                },
            });

            const abDispatcher = data.list_dispatcher.ab_dispatcher;
            setDispatcher(abDispatcher);
            setABTestsDimensions(abDispatcher);

            gaSet({ dimension22: 'yes' }); // GA dimension to distinguish node and python stacks
            setUserDimensions(data.analytics_user);
            window.MA_USER = data.analytics_user;

            if (gaPageTemplates) {
                gaSet(gaPageTemplates);
            }

            if (shouldSendCriteoPageView) {
                sendCriteoEvent({ event: 'viewPage' });
            }

            // We provide a path without query params for a better comprehension in GA
            const pagePathWithoutQueryParams = router.asPath.split('?')[0];
            gaSet({ page: pagePathWithoutQueryParams });
            gaPageView(pagePathWithoutQueryParams, { pageTitle });

            gaSet({
                dimension17: abDispatcher.www_next_home_qsl_promote
                    ? 'homepage:search_block:selling_property'
                    : 'homepage:search_block:control',
            });

            window.MA_WEBVITALS = {
                gaEvents: window.MA_WEBVITALS?.gaEvents,
                hasSendPageView: true,
            };

            gaPageEvents?.forEach((event) => {
                gaEvent(event);
            });

            if (window.MA_WEBVITALS.gaEvents) {
                window.MA_WEBVITALS.gaEvents.forEach((event) => {
                    gaEvent(event);
                });
                window.MA_WEBVITALS.gaEvents = undefined;
            }
        });

        return () => {
            mounted = false;
        };
    }, [
        dataLayerPageCategory,
        dataLayerPageTemplate,
        gaPageEvents,
        gaPageTemplates,
        isErrorPage,
        pageTitle,
        router.asPath,
        setDispatcher,
        setUserState,
        shouldSendCriteoPageView,
    ]);
    return { userState, dispatcher };
};

export default usePageContext;

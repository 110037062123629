import Gender from '@/entities/enums/Gender';
import type DataLayer from '@/entities/types/DataLayer';
import type UserProType from '@/entities/types/UserProType';
import type { userState } from '@/front/context/UserContext';

export const analyticsUserTypeDict: Record<string, UserProType> = {
    B2C: 'private_person',
    'B2B:Agent': 'agency',
    'B2B:Banquier': 'bank',
    'B2B:CGP': 'wealth_management',
    'B2B:Assureur': 'insurance',
    'B2B:Promoteur': 'property_development',
    'B2B:Institutionnel': 'foundations',
    'B2B:Mandataire': 'representative',
    'B2B:Syndic': 'property_management',
    'B2B:Notaire': 'notary_office',
    'B2B:Courtier': 'brokerage',
    'B2B:Diagnostiqueur': 'diagnostics',
    'B2B:ExpertImmo': 'property_advice',
    'B2B:B2B:Autre': 'other',
};

export const mapDataLayerUser = (data: DataLayer): userState => {
    if (Object.keys(data.user).length === 0) return { userHasBeenRequested: true };
    return {
        userHasBeenRequested: true,
        realtor: data.realtor ?? undefined,
        user: {
            id: data.user.id,
            firstName: data.user.firstname,
            lastName: data.user.lastname,
            gender: data.user.gender === 'F.' ? Gender.FEMALE : Gender.MALE,
            login: data.user.login,
            phone: data.user.phone && data.user.phone.trim() !== '' ? data.user.phone : null,
            email: data.user.email && data.user.email.trim() !== '' ? data.user.email : null,
            type: analyticsUserTypeDict[data.analytics_user.type] || null,
            function: null,
            hasProAccess: data.user.type === 'Pro' && data.realtor !== null,
        },
    };
};

declare global {
    interface Window {
        Didomi: {
            preferences: {
                show: () => void;
                isVisible: () => boolean;
            };
            isConsentRequired: () => boolean;
            shouldConsentBeCollected: () => boolean;
        };
        thor: {
            onConsent: () => void;
        };
    }
}

export const getConsent = () => {
    const userConsentInterval = setInterval(() => {
        // as long as the user has not interact with the cookie banner, needUserInteraction is true
        const shouldConsentBeCollected = window.Didomi?.shouldConsentBeCollected();
        if (shouldConsentBeCollected === false) {
            clearInterval(userConsentInterval);
            window.thor.onConsent();
        }
    }, 1000);
};

class HTTPError extends Error {
    url: string;

    constructor(message: string, url: string) {
        super(message);
        this.name = `HTTPError ${url}`;
        this.url = url;
    }
}

/**
 * Trasform a fetch response error to a standard Error object
 * @param response Fetch response error
 */
export const httpError = (response: Response) => {
    const error = new HTTPError(`${response.statusText} ${response.status}`, response.url);
    return error;
};

import { Spinner } from '@ma-react/components';

import styles from './loading-overlay.module.scss';

const LoadingOverlay = () => (
    <div className={styles['overlay']}>
        <Spinner />
    </div>
);

export default LoadingOverlay;

import type FieldsObject from '@/entities/types/FieldsObject';

export const getSearchParamsAsObject = () => {
    return window.location.search
        .replace(/^\?/, '')
        .split('&')
        .reduce((previous: FieldsObject, current) => {
            if (current !== '') {
                const param = current.split('=');
                // eslint-disable-next-line no-param-reassign
                previous[param[0]] =
                    param.length > 1 ? decodeURIComponent(param[1].replace(/\+/g, ' ')) : true;
            }

            return previous;
        }, {});
};

export interface ReferrerOriginParams {
    referrer: string;
}

/**
 * Determine referrer origin from search params and `document.refererrer` attribute.
 * If `referrer` params is set, param is used. Otherwise, browser
 * referrer is used by matching it against known values.
 */
export const referrerOrigin = (params?: ReferrerOriginParams) => {
    if (params && params.referrer) {
        return params.referrer;
    }

    return 'external';
};
